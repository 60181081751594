<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <PublicFormTemplateDetailGeneralInfo
          ref="publicGeneralInfo"
          v-if="isInternalAdmin"
          :formTemplate="formTemplate"
          :groups="groups"
          @saved="getFormTemplateDetails()"
        />
        <FormTemplateDetailGeneralInfo
          ref="generalInfo"
          v-if="!isInternalAdmin"
          :formTemplate="formTemplate"
          :groups="groups"
          :employees="employees"
          @saved="getFormTemplateDetails()"
        />
      </v-col>
    </v-row>
    <v-row v-if="formTemplate.id != 0">
      <v-col cols="12" md="4">
        <SearchBar
          v-if="!isOrdering"
          searchLabel="Search by section name..."
          @search="search.section = $event"
        />
      </v-col>
      <v-col cols="8" v-if="!isMobile">
        <SearchBar
          v-if="!isOrdering"
          searchLabel="Search by question..."
          @search="search.element = $event"
        />
      </v-col>
    </v-row>
    <v-row v-if="!isNew && !(isSearching && formTemplate.sections.length == 0)">
      <v-col cols="12" md="4">
        <FormTemplateSections
          :sections="formTemplate.sections"
          :isOrdering="isOrdering"
          :isSearching="isSearching"
          @isOrdering="isOrdering = 1"
          @goToSection="goToSection($event)"
          @updateSection="updateSection($event)"
          @deleteSection="deleteSection($event)"
          @onOrder="changeSectionOrder($event)"
          @updateSectionPositions="updateSectionPositions()"
        />
      </v-col>
      <v-col cols="12" v-if="isMobile">
        <SearchBar
          v-if="!isOrdering"
          searchLabel="Search by question..."
          @search="search.element = $event"
        />
      </v-col>
      <v-col cols="12" md="8">
        <v-row>
          <v-col
            v-for="section in formTemplate.sections"
            :key="section.id"
            cols="12"
          >
            <FormTemplateElements
              :ref="'section' + section.sectionId"
              :section="section"
              :isSearching="isSearching"
              :sections="formTemplate.sections"
              :elementTypes="elementTypes"
              :isOrdering="isOrdering"
              @elementSaved="onElementSave($event)"
              @isOrdering="isOrdering = 1"
              @onOrder="changeElementOrder(section, $event)"
              @updateElementPositions="updateElementPositions(section)"
              @onDuplicate="insertDuplicatedElement($event)"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="isSearching && formTemplate.sections.length == 0">
      <v-col cols="12" align="center">No results found</v-col>
    </v-row>
  </v-container>
</template>

<script>
import FormTemplateDetailGeneralInfo from '@/components/FormTemplates/FormTemplateDetailGeneralInfo.vue';
import PublicFormTemplateDetailGeneralInfo from '@/components/FormTemplates/PublicFormTemplateDetailGeneralInfo.vue';
import FormTemplateSections from '@/components/FormTemplates/FormTemplateSections.vue';
import FormTemplateElements from '@/components/FormTemplates/FormTemplateElements.vue';
import SearchBar from '@/components/SearchBar.vue';
export default {
  name: 'FormTemplateDetail',
  components: {
    FormTemplateDetailGeneralInfo,
    FormTemplateSections,
    SearchBar,
    FormTemplateElements,
    PublicFormTemplateDetailGeneralInfo,
  },
  computed: {
    isSearching() {
      return this.search.section != '' || this.search.element != '';
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isNew() {
      return this.$route.params.id == 0;
    },
    isInternalAdmin() {
      return (
        this.$store.getters.user.type ==
        this.$constants.USER_TYPES.INTERNAL_ADMIN
      );
    },
  },
  data() {
    return {
      groups: [],
      formTemplate: {
        sections: [],
      },
      employees: [],
      isOrdering: 0,
      search: { section: '', element: '' },
      elementTypes: [],
    };
  },
  watch: {
    search: {
      deep: true,
      handler() {
        this.getFormTemplateDetails();
      },
    },
  },
  methods: {
    async init() {
      await this.getNecessaryData();
      this.getFormTemplateDetails();
    },
    async getNecessaryData() {
      const url = this.isInternalAdmin
        ? 'get-necessary-data-for-public-form-template-details?format=json'
        : 'get-necessary-data-for-form-template-details?format=json';
      await this.$axios
        .post(url, { loaderText: 'Loading...' })
        .then((response) => {
          this.groups = response.data.groups;
          this.elementTypes = response.data.elementTypes;
          if (!this.isInternalAdmin) {
            this.employees = response.data.employees;
          }
        })
        .catch((error) => error);
    },
    getFormTemplateDetails() {
      const params = {
        loaderText: 'Loading...',
        id: this.formTemplate.id,
        search: this.search,
      };
      const url = this.isInternalAdmin
        ? 'get-public-form-template-details?format=json'
        : 'get-form-template-details?format=json';
      this.$axios
        .post(url, params)
        .then((response) => {
          if (this.isNew) {
            this.formTemplate = JSON.parse(
              JSON.stringify(this.$store.getters.selectedFormTemplate),
            );
          } else {
            this.formTemplate = response.data;
          }
          this.initGeneralInfoSection();
        })
        .catch((error) => error);
    },
    initGeneralInfoSection() {
      this.$nextTick(() => {
        if (this.isInternalAdmin) {
          this.$refs.publicGeneralInfo.loadDetails();
        } else {
          this.$refs.generalInfo.loadDetails();
        }
      });
    },
    goToSection(sectionId) {
      const options = {
        duration: 500,
        offset: 0,
        easing: 'easeInOutCubic',
      };
      this.$vuetify.goTo(this.$refs[`section${sectionId}`][0], options);
    },
    updateSection(section) {
      const index = this.formTemplate.sections.findIndex(
        (s) => s.sectionId == section.sectionId,
      );

      if (index == -1) {
        section.isOrdering = 0;
        this.formTemplate.sections.push(section);
      } else {
        this.$set(this.formTemplate.sections, index, section);
      }
    },
    deleteSection(sectionId) {
      const index = this.formTemplate.sections.findIndex(
        (s) => s.sectionId == sectionId,
      );

      this.formTemplate.sections.splice(index, 1);
    },
    changeSectionOrder(event) {
      const movedItem1 = { ...this.formTemplate.sections[event.index] };

      const movedItem2 = {
        ...this.formTemplate.sections[event.index + event.value],
      };

      this.$set(this.formTemplate.sections, event.index, movedItem2);
      this.$set(
        this.formTemplate.sections,
        event.index + event.value,
        movedItem1,
      );
    },
    updateSectionPositions() {
      const sections = this.formTemplate.sections.map((section, index) => {
        section.sectionPosition = index + 1;
        return this.isInternalAdmin
          ? {
              id: section.sectionId,
              position: section.sectionPosition,
              publicFormTemplateId: section.publicFormTemplateId,
              publicFormTemplateGroupId: section.publicFormTemplateGroupId,
            }
          : {
              id: section.sectionId,
              position: section.sectionPosition,
              formTemplateId: section.formTemplateId,
              formTemplateGroupId: section.formTemplateGroupId,
            };
      });
      const params = {
        loaderText: 'Loading...',
        sections: sections,
      };
      const url = this.isInternalAdmin
        ? 'update-public-form-template-section-positions?format=json'
        : 'update-form-template-section-positions?format=json';
      this.$axios
        .post(url, params)
        .then(() => (this.isOrdering = 0))
        .catch((error) => error);
    },
    onElementSave(element) {
      const sectionIndex = this.formTemplate.sections.findIndex(
        (s) => s.sectionId == element.sectionId,
      );
      const section = this.formTemplate.sections[sectionIndex];
      const elementIndex = section.elements.findIndex(
        (e) => e.elementId == element.elementId,
      );

      if (elementIndex == -1) {
        section.elements.push(element);
      } else {
        this.$set(section.elements, elementIndex, element);
      }
    },
    changeElementOrder(section, event) {
      const movedItem1 = { ...section.elements[event.index] };

      const movedItem2 = {
        ...section.elements[event.index + event.value],
      };

      this.$set(section.elements, event.index, movedItem2);
      this.$set(section.elements, event.index + event.value, movedItem1);
    },
    updateElementPositions(section) {
      const elements = section.elements.map((element, index) => {
        element.elementPosition = index + 1;
        return this.isInternalAdmin
          ? {
              id: element.elementId,
              position: element.elementPosition,
              sectionId: element.sectionId,
              publicFormTemplateId: element.publicFormTemplateId,
              publicFormTemplateGroupId: element.publicFormTemplateGroupId,
            }
          : {
              id: element.elementId,
              position: element.elementPosition,
              sectionId: element.sectionId,
              formTemplateId: element.formTemplateId,
              formTemplateGroupId: element.formTemplateGroupId,
            };
      });
      const params = {
        loaderText: 'Loading...',
        elements: elements,
      };
      const url = this.isInternalAdmin
        ? 'update-public-form-template-element-positions?format=json'
        : 'update-form-template-element-positions?format=json';
      this.$axios
        .post(url, params)
        .then(() => (this.isOrdering = 0))
        .catch((error) => error);
    },
    insertDuplicatedElement(element) {
      const section = this.formTemplate.sections.find(
        (s) => s.sectionId == element.sectionId,
      );

      if (section != undefined) {
        section.elements.push(element);
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.formTemplate.id = this.$route.params.id;
      this.init();
    });
  },
};
</script>

<style></style>
